<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-briefcase"></i><strong> Employés</strong>
              <div class="card-header-actions">
                <b-button id="v-step-1" variant="success" @click="onExport" title="Exporter en PDF" class="mr-2"
                          v-b-tooltip.hover>
                  <i class="fa fa-upload"></i>
                </b-button>
                
                <b-button id="v-step-0" variant="primary" @click="addEmployeeModal = true">
                  <i class="fa fa-plus"></i>
                  Ajouter un employé
                </b-button>
                
                <b-modal :title="modalTitle" class="modal-primary" v-model="addEmployeeModal"
                         @hide="onModalClose" size="lg">
                  <b-form-row>
                    <b-col v-show="!gradeModeEmployee">
                      <c-input container-class="mb-1 required-input" label="Nom de l'employé" placeholder="Ex: KRATOS"
                               v-model="lastName" :state="lastNameState">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <c-input container-class="mb-1 required-input" label="Prénom de l'employé" placeholder="Ex: Bob"
                               v-model="firstName" :state="firstNameState">
                        Veuillez saisir un prénom
                      </c-input>
                      
                      <c-input container-class="mb-1 required-input" label="Numéro de CNI de l'employé"
                               placeholder="Ex: 123456789"
                               v-model="IDNumber" :state="IDNumberState">
                        Numéro de CNI invalide
                        <template slot="infoMessage">
                          Entrez le numéro de votre pièce d'identité.
                        </template>
                      </c-input>
                      
                      <c-input container-class="mb-1 required-input" type="tel" label="Numéro de téléphone de l'employé"
                               placeholder="Ex: 680123456" v-model="phoneNumber" :state="phoneNumberState">
                        Numéro de téléphone invalide
                        <template slot="infoMessage">
                          A gauche, vous pouvez selectionner le pays dans lequel vous avez enregistré le numéro.
                        </template>
                      </c-input>
                      
                      <c-input container-class="mb-2 required-input" type="textarea" label="Adresse de l'employé"
                               placeholder="Entrez une adresse..." v-model="address">
                      </c-input>
                      <c-input container-class="mb-3 required-input" type="date" label="Date d'embauche"
                               v-model="hiringDate"></c-input>
                    </b-col>
                    <b-col>
                      
                      <div class="text-center">
                        <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                        
                        <c-input type="image" v-model="picture" default="DEFAULT_USER_PICTURE"
                                 :hide-select="gradeModeEmployee"></c-input>
                      </div>
                      <c-input container-class="mb-1 required-input" type="select" label="Type d'employé"
                               v-model="employeeType"
                               :options="employeeTypeList" :state="employeeTypeState" :disabled="editModeEmployee">
                        <template slot="first">
                          <option :value="null">-- Sélectionnez un type d'employé --</option>
                        </template>
                        Veuillez sélectionner un type d'employé
                      </c-input>
                      
                      <c-input container-class="mb-2 required-input" type="select" label="Catégorie d'employé"
                               v-model="employeeCategory"
                               :options="employeeCategoryList" :state="employeeCategoryState"
                               :disabled="editModeEmployee">
                        <template slot="first">
                          <option :value="null">-- Sélectionnez une catégorie d'employé --</option>
                        </template>
                        Veuillez sélectionner une catégorie d'employé
                      </c-input>
                      
                      <c-input container-class="mb-2 required-input" type="select" label="Titre de l'employé"
                               v-model="employeeTitle"
                               :options="employeeTitleList" :state="employeeTitleState" :disabled="editModeEmployee">
                        <template slot="first">
                          <option :value="null">-- Sélectionnez un titre d'employé --</option>
                        </template>
                        Veuillez sélectionner titre d'employé
                      </c-input>
                    
                    </b-col>
                  </b-form-row>
                  
                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmit"
                                    :fetching="fetchingAddEmployee">
                      {{submitBtnText}}
                    </button-spinner>
                    <b-button variant="secondary" @click="addEmployeeModal = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
                <b-modal title="Historique de l'employé" class="modal-primary" v-model="consultHistoryModeEmployee"
                         size="lg">
                  <div class="text-center" v-if="fetchingHistoryList">
                    <b-spinner></b-spinner>
                    <br>
                    Chargement...
                  </div>
                  <b-row v-else-if="histories.length" class="exploitations">
                    <c-table :table-data="histories" :fields="fieldsHistory" :per-page=5 striped
                             outlined></c-table>
                  </b-row>
                  <p class="text-center" v-else>Cet employé n'a pas d'historique</p>
                </b-modal>
              </div>
            </div>
            <div class="text-center" v-if="fetchingEmployeeList">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <b-row v-else-if="employees.length" class="exploitations">
              <c-table :table-data="employees" :fields="fields" :actions="actions" :per-page=5 striped
                       outlined></c-table>
            </b-row>
            <p class="text-center" v-else>Aucun employé ne travaille dans cette exploitation.</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Regex, Toast, IO, env, asset, functionGeneratorJSON, endGuide} from "../../../helpers"
  
  export default {
    name: "ExploitationEmployees",
    title: "PIA - Employés",
    data() {
      return {
        employees: [],
        histories: [],
        fields: [
          {key: 'picture', label: 'Photo', asset: this.asset},
          {key: 'name', label: 'Nom', sortable: true},
          {key: 'type', label: "Type", sortable: true},
          {key: 'category', label: "Catégorie", sortable: true},
          {key: 'title', label: "Titre"},
          {key: 'hiringDate', label: "Embauché", sortable: true},
          {key: 'updatedDate', label: "Mise à jour", sortable: true},
          {key: 'actions', label: 'Actions'},
        ],
        actions: [
          {type: 'primary', icon: 'icon-note', title: 'Modifier les infos de bases', handler: this.editEmployee},
          {type: 'warning', icon: 'fa fa-graduation-cap', title: 'Changer de Grade', handler: this.editGradeEmployee},
          {type: 'secondary', icon: 'fa fa-history', title: "Consulter son historique", handler: this.consultHistory},
          {type: 'danger', icon: 'icon-trash', title: 'Supprimer', handler: this.deleteEmployee},
        ],
        fieldsHistory: [
          {key: 'type', label: "Type"},
          {key: 'category', label: "Catégorie"},
          {key: 'title', label: "Titre"},
          {key: 'startDate', label: "Date de début", sortable: true},
          {key: 'endDate', label: "Date de fin", sortable: true},
        ],
        addEmployeeModal: false,
        editModeEmployee: false,
        gradeModeEmployee: false,
        consultHistoryModeEmployee: false,
        picture: null,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        IDNumber: '',
        address: '',
        employeeType: null,
        employeeCategory: null,
        employeeTitle: null,
        hiringDate: '',
        submitted: false,
        error: null,
        fetchingAddEmployee: false,
        fetchingEmployeeList: false,
        fetchingHistoryList: false,
        selectedEmployee: null,
        
        employeeTypeList: [],
        employeeCategoryTitleList: [],
        fetchingEmployeeTypeList: false,
        fetchingEmployeeCategoryList: false,
        
        myOptions: {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: 'Quittez',
            buttonPrevious: 'Précédent',
            buttonNext: 'Suivant',
            buttonStop: 'Terminé'
          }
        },
      }
    },
    created() {
      this.fetchingEmployeeList = true
      Api.get('/exploitation/employee/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.employees = res.data.data.map(item => ({
              ...item,
              name: item.firstName + ' ' + item.lastName,
              type: item.employeeType.name,
              category: item.employeeCategory.name,
              title: item.employeeTitle.name,
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingEmployeeList = false
        })
      
      this.fetchingEmployeeTypeList = true
      Api.get('/exploitation/employee/type/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.employeeTypeList = res.data.data.map(item => ({
              text: item.name,
              value: item.id
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingEmployeeTypeList = false
        })
      
      this.fetchingEmployeeCategoryList = true
      Api.get('/exploitation/employee/category/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.employeeCategoryTitleList = res.data.data.map(item => ({
              value: item.id,
              text: item.name,
              titles: item.titles
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingEmployeeCategoryList = false
        })
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      },
    },
    mounted() {
      // this.$tours['myTour'].start()
      
      Toast.guide_msg(
        "Créer des employes qui travailleront sur votre exploitation",
        this.openWarehousesList,
        this.openEmployee,
        functionGeneratorJSON("Etape precedente"),
        functionGeneratorJSON("Créer un employé"),
        4,
        [this.exploitationId],
        null,
        this.nextComposantToVisit,
        null,
        null,
        endGuide
      )
      
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      _picture() {
        return this.picture ? this.picture : env('DEFAULT_USER_PICTURE')
      },
      modalTitle() {
        if (this.gradeModeEmployee)
          return "Changer le grade d'un empployé"
        return this.selectedEmployee ? 'Modifier un employé' : 'Nouvel employé'
      },
      submitBtnText() {
        if (this.gradeModeEmployee)
          return "Changer son grade"
        return this.selectedEmployee ? 'Enregistrer' : 'Ajouter'
      },
      firstNameState() {
        return !this.submitted || this.firstName.trim().length >= 3 ? null : false
      },
      lastNameState() {
        return !this.submitted || this.lastName.trim().length >= 3 ? null : false
      },
      phoneNumberState() {
        return !this.submitted || Regex.isEmptyOrPhoneNumber(this.phoneNumber) ? null : false
      },
      IDNumberState() {
        return !this.submitted || Regex.isValidIDNumber(this.IDNumber) ? null : false
      },
      employeeTypeState() {
        return !this.submitted || this.employeeType != null ? null : false
      },
      employeeCategoryState() {
        return !this.submitted || this.employeeCategory != null ? null : false
      },
      employeeTitleState() {
        return !this.submitted || this.employeeTitle != null ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      },
      employeeCategoryList() {
        return this.employeeCategoryTitleList
      },
      employeeTitleList() {
        const titleList = this.employeeCategoryTitleList.find(item => item.value === this.employeeCategory)
        console.info(titleList)
        return titleList ? titleList.titles.map(item => ({
          text: item.name,
          value: item.id
        })) : []
      }
    },
    methods: {
      asset(path) {
        return asset(path, env('DEFAULT_USER_PICTURE'))
      },
      onPictureChange(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        
        const reader = new FileReader();
        const vm = this;
        
        reader.onload = (e) => {
          vm.picture = e.target.result;
        };
        reader.readAsDataURL(files[0]);
      },
      valid() {
        return this.firstName.trim().length >= 3
          && this.lastName.trim().length >= 3
          && Regex.isEmptyOrPhoneNumber(this.phoneNumber)
      },
      onModalClose() {
        this.selectedEmployee = null
        this.firstName = ''
        this.lastName = ''
        this.phoneNumber = ''
        this.submitted = false
        this.error = null
        this.fetchingAddEmployee = false
        this.editModeEmployee = false
        this.gradeModeEmployee = false
        this.IDNumber = ''
        this.address = ''
        this.employeeType = null
        this.employeeCategory = null
        this.employeeTitle = null
        this.hiringDate = ''
        this.picture = null
      },
      addEmployee() {
        this.submitted = true
        if (!this.valid()) return
        
        this.fetchingAddEmployee = true
        Api.post('/exploitation/employee/create', {
          exploitationId: this.exploitationId,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          picture: this.picture,
          IDNumber: this.IDNumber,
          address: this.address,
          employeeTypeId: this.employeeType,
          employeeCategoryId: this.employeeCategory,
          employeeTitleId: this.employeeTitle,
          hiringDate: this.hiringDate,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.addEmployeeModal = false
              const employee = res.data.data
              this.employees.push({
                ...employee,
                name: employee.firstName + ' ' + employee.lastName,
                type: employee.employeeType.name,
                category: employee.employeeCategory.name,
                title: employee.employeeTitle.name,
              })
              
              Toast.success('Employé ajouté avec succès !')
              
              Toast.guide_msg(
                "Employé ajouté avec succès !",
                this.openEmployee,
                this.nextComposantToVisit,
                functionGeneratorJSON("Créer un autre employé"),
                functionGeneratorJSON("Etape suivante"),
                4,
                [this.exploitationId]
              )
              
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingAddEmployee = false
          })
      },
      updateEmployee() {
        this.fetchingAddEmployee = true
        Api.post('/exploitation/employee/update', {
          exploitationId: this.exploitationId,
          employeeId: this.selectedEmployee.id,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          picture: Regex.isNullOrURL(this.picture) ? null : this.picture,
          IDNumber: this.IDNumber,
          address: this.address,
          employeeTypeId: this.employeeType,
          employeeCategoryId: this.employeeCategory,
          employeeTitleId: this.employeeTitle,
          hiringDate: this.hiringDate,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.addEmployeeModal = false
              this.employees = this.employees.filter((item, index, array) => item.id !== this.selectedEmployee.id)
              const employee = res.data.data
              this.employees.push({
                ...employee,
                name: employee.firstName + ' ' + employee.lastName,
                type: employee.employeeType.name,
                category: employee.employeeCategory.name,
                title: employee.employeeTitle.name,
              })
              
              Toast.success('Employé mis à jour avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingAddEmployee = false
          })
      },
      updateGrade() {
        this.fetchingAddEmployee = true
        Api.post('/exploitation/employee/grade/change', {
          exploitationId: this.exploitationId,
          employeeId: this.selectedEmployee.id,
          employeeTypeId: this.employeeType,
          employeeCategoryId: this.employeeCategory,
          employeeTitleId: this.employeeTitle,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.addEmployeeModal = false
              this.employees = this.employees.filter((item, index, array) => item.id !== this.selectedEmployee.id)
              const employee = res.data.data
              this.employees.push({
                ...employee,
                name: employee.firstName + ' ' + employee.lastName,
                type: employee.employeeType.name,
                category: employee.employeeCategory.name,
                title: employee.employeeTitle.name,
              })
              
              Toast.success("Changement du grade de l'employé avec succès !")
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingAddEmployee = false
          })
      },
      editEmployee(employee) {
        this.selectedEmployee = employee
        this.firstName = employee.firstName
        this.lastName = employee.lastName
        this.phoneNumber = employee.phoneNumber
        this.addEmployeeModal = true
        this.editModeEmployee = true
        this.picture = asset(employee.picture)
        this.IDNumber = employee.IDNumber
        this.address = employee.address
        this.employeeType = employee.employeeType.id
        this.employeeCategory = employee.employeeCategory.id
        this.employeeTitle = employee.employeeTitle.id
        this.hiringDate = employee.hiringDate.split('/').reverse().join('-')
      },
      editGradeEmployee(employee) {
        this.selectedEmployee = employee
        this.addEmployeeModal = true
        this.gradeModeEmployee = true
        this.picture = asset(employee.picture)
        this.employeeType = employee.employeeType.id
        this.employeeCategory = employee.employeeCategory.id
        this.employeeTitle = employee.employeeTitle.id
      },
      consultHistory(employee) {
        this.fetchingHistoryList = true
        this.consultHistoryModeEmployee = true
        Api.get('/exploitation/employee/grade/history', {
          exploitationId: this.exploitationId,
          employeeId: employee.id
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.histories = res.data.data.map(item => {
                let endDate = item.endDate
                if (endDate == null)
                  endDate = "jusqu'aujoud'hui"
                return {
                  ...item,
                  type: item.employeeType.name,
                  category: item.employeeCategory.name,
                  title: item.employeeTitle.name,
                  endDate
                }
              })
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingHistoryList = false
          })
      },
      onSubmit() {
        if (this.gradeModeEmployee)
          return this.updateGrade()
        return this.selectedEmployee ? this.updateEmployee() : this.addEmployee()
      },
      deleteEmployee(employee) {
        const res = confirm(`Etes-vous sûr de vouloir supprimer l'employé "${employee.name}" ?`)
        if (res) {
          Api.post('/exploitation/employee/delete', {
            exploitationId: this.exploitationId,
            employeeId: employee.id
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                this.employees = this.employees.filter((item, index, array) => item.id !== employee.id)
                Toast.success('Employé supprimé avec succès !')
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(error => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              // this.fetchingAddEmployee = false
            })
        }
      },
      onExport() {
        IO.exportDataTable(this.employees, 'employees', {
          title: 'Exploitation: ' + this.$store.getters.exploitationName,
          name: 'Liste des employés',
          headers: [
            {title: 'Photo', key: 'picture', width: 'auto', asset: this.asset},
            {title: 'Nom', key: 'name', width: '*'},
            {title: 'Type', key: 'type', width: 'auto'},
            {title: 'Catégorie', key: 'category', width: 'auto'},
            {title: 'Numéro de téléphone', key: 'phoneNumber', width: 'auto'},
            {title: 'CNI', key: 'IDNumber', width: 'auto'},
            {title: 'Adresse', key: 'address', width: 'auto'},
            {title: "Date d'embauche", key: 'hiringDate', width: 'auto'},
          ]
        })
      },
      nextComposantToVisit() {
        this.$router.push('/exploitation/' + this.exploitationId + '/production/create')
      },
      openWarehousesList() {
        this.$router.push('/exploitation/' + this.exploitationId + '/management/warehouses/list')
      },
      openEmployee() {
        this.addEmployeeModal = true
      }
    }
  }
</script>

<style scoped>
  @import "~vue-form-wizard/dist/vue-form-wizard.min.css";
  @import "~vue-tel-input/dist/vue-tel-input.css";
</style>
<style>
  
  .tel-input-container{
    width: 100%;
    border-color: #e4e7ea !important;
  }
  
  .tel-input-container .dropdown{
    background-color: #f0f3f5;
  }
  
  .tel-input-container .tel-input{
    border-left: 1px solid #e4e7ea !important;
    padding: 0.375rem 0.75rem !important;
  }
  
  .left-icon{
    padding: 0 20px;
  }

</style>
<style scoped>
  #employee-img{
    max-height: 100px;
  }

</style>
